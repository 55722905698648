import React from "react"
import Layout from "../components/Layout"
import SEO from '../components/SEO'
import PageHero from '../components/PageHero'
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import { ImHome } from "react-icons/im"
import { IoRocketOutline } from "react-icons/io5"
import SubscribeForm from "../components/SubscribeForm"

const Tags = ({ pageContext, data, location }) => {
    const { tag, tagKebab, currentPage, tagnumPages } = pageContext
    const { edges, totalCount } = data.allMarkdownRemark
    const tagHeader = `${totalCount} post${
        totalCount === 1 ? "" : "s"
    } tagged with "${tag}"`

    // const _ = require("lodash")
    const isFirst = currentPage === 1
    const isLast = currentPage === tagnumPages
    const prevPage = currentPage -1 === 1 ? tagKebab : (tagKebab+(currentPage -1)).toString()
    const nextPage = (tagKebab+(currentPage +1)).toString()

    const tagname = tag.replace(/\b([a-zA-Z]|\d+)/g, function($1) { return $1.toUpperCase(); })

    return (
        <Layout location={location}>
          <SEO title={'Posts Tagged with '+tag} description={'All Rokit Media blog posts tagged with '+tag+'. Select a post on this page to continue reading the article tagged with '+tag} />
            <PageHero heading={tagHeader} />
            <section className="container mx-auto max-w-7xl text-center pt-4">
                <div className="px-8 pt-4">
                    <ul className="text-left font-mono text-xs md:text-base">
                        <li className="inline-block border-black border-r-2">
                          <Link to="/">
                              <span className="inline-block pr-2"><ImHome /></span>
                              <span className="px-2">Home</span>
                          </Link>
                        </li>
                        <li className="inline-block border-black border-r-2">
                          <Link to="/tags/">
                              <span className="px-2">Tags</span>
                          </Link>
                        </li>
                        <li className="inline-block">
                            <span className="px-2">{tagname}</span>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-col lg:flex-row">
                    {/* Main Content */}
                    <div className="p-6 lg:w-2/3">
                        <div className="text-left">
                                {edges.map(({ node }) => {
                                const { slug } = node.fields
                                const { title, date } = node.frontmatter
                                return (
                                    <div key={slug} className="w-full hover:text-pink-700">
                                        <Link to={slug} itemProp='url'>
                                        <h2 className="text-purple-900 text-lg sm:text-xl md:text-2xl text-left py-1 font-mono"><span>{title}</span></h2>
                                        <p className="text-xs md:text-base">Posted: {date}</p>
                                        <p className="text-lg sm:text-xl font-light text-left py-3">
                                            {node.excerpt}
                                        </p>
                                        </Link>
                                    </div>
                                )
                                })}
                            <Link to="/tags/">
                              <h3 className="heading-box-text3 text-2xl md:text-3xl lg:text-4xl p-4">
                                  <span className="pulse">List All Tags</span>
                              </h3>
                            </Link>
                        </div>
                        <div className="flex flex-col justify-between sm:flex-row pt-8">
                            <div className="text-left pr-4">
                                {!isFirst && (
                                    <Link className="pulse heading-box-text-blog1 text-2xl px-6" to={prevPage}><span>Previous Page</span></Link>
                                )}
                            </div>
                            <div className="text-right pl-4">
                                {!isLast && (
                                    <Link className="pulse heading-box-text-blog2 text-2xl px-6" to={nextPage}><span>Next Page</span></Link>
                                )}
                            </div>  
                        </div>
                    </div>
                    {/* Sidebar */}
                    <div className="px-6 pt-6 pb-0 lg:pb-6 w-2/3 lg:w-1/3 flex flex-row lg:flex-col items-start">
                        <div className="lg:sticky top-24">
                            <div className="text-base py-0 lg:py-2 pl-2 lg:pl-0 text-left">
                                <div className="hidden lg:inline-flex items-center"><IoRocketOutline /><p>-Rokit Media</p></div>
                            </div>
                            <p className="text-xs hidden lg:inline-block text-left">Rokit Media is a modern digital web design and development agency based in Bishop's Stortford within the Herts and Essex region of the United Kingdom.</p>
                            <p className="text-xs hidden lg:inline-block text-left">We build Websites and Web Apps the modern way, which delivers blazing fast performance, higher security and cheaper to run.</p>
                            <div className="hidden lg:inline-block">
                                <SubscribeForm formid="subscribe-form-1" />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center lg:hidden">
                        <SubscribeForm formid="subscribe-form-2" />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { frontmatter: { tags: { in: [$tag] } } },
      limit: $limit, 
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 250)
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
        }
      }
    }
  }
`